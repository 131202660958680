// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDn-VE2exIlGA3YjGHUrlKBVYKQ2RLtHB4",
  authDomain: "gasbillnews1708.firebaseapp.com",
  databaseURL: "https://gasbillnews1708-default-rtdb.firebaseio.com",
  projectId: "gasbillnews1708",
  storageBucket: "gasbillnews1708.appspot.com",
  messagingSenderId: "993887019981",
  appId: "1:993887019981:web:fc7309f51cb74a6c0c9a84",
  measurementId: "G-0BGJ4F6DJ8"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };