import React, { useState, useEffect } from 'react';
import { app } from './firebase';
import { getDatabase, ref, onValue } from 'firebase/database';
import 'firebase/database';
import './Error.css';

const db = getDatabase(app);

function Footer() {
  useEffect(() => {
    document.title = "Page";
  }, []);
  
  const [tableData, setTableData] = useState({});

  useEffect(() => {
    const dataRef = ref(db, 'Data'); // Reference to the root

    const handleDataChange = (snapshot) => {
      if (snapshot.exists()) {
        const dataObj = snapshot.val();
        setTableData(dataObj);
      } else {
        console.log('No data available.');
      }
    };

    // Listen for real-time changes
    const dataListener = onValue(dataRef, handleDataChange);

    // Clean up the listeners when the component unmounts
    return () => {
      dataListener(); // Unsubscribe the listener
    };
  }, []);

  const reversedTableData = Object.entries(tableData).reverse();

  return (
    <div>
      <h1 style={{ textAlign: 'center', marginTop:'-5px',fontSize:'25px' }}>Data</h1>
      <p style={{ textAlign: 'center', color: 'red',padding: '0px', marginTop: '-20px'  }}>show here</p>
      <div style={{ marginLeft: '10px', marginRight: '10px' }}>
        {reversedTableData.map(([key, value], index) => (
          <div key={index} className='box'>
            {/* <p>Key: {key}</p> */}
            {Object.entries(value).map(([dataKey, dataValue], dataIndex) => (
              <p key={dataIndex}>
                {dataKey.charAt(0).toUpperCase() + dataKey.slice(1)}: {dataValue}
              </p>
            ))}
            {/* <hr /> */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Footer;
